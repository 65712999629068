import { Box, Card, Paper, TextField } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../context/toast/ToastContext";
import { LoadingButton } from "../../components/button/Button";
import { useAuthenticate } from "./network/resolver";
import { ENV_LISTS } from "../../core/EnvTranspiler";

export default function Login() {
  const toast = useToast();
  const { mutate: mutateLogin, isLoading: loadingLogin } = useAuthenticate();
  const [username, setUsername] = useState<string>(
    ENV_LISTS.MODE === "production" ? "" : "admin@email.com"
  );
  const [password, setPassword] = useState<string>(
    ENV_LISTS.MODE === "production" ? "" : "Admin@toko!"
  );

  function doLogin() {
    if (!username || !password) {
      toast.show("Mohon lengkapi field");
      return;
    }
    mutateLogin({
      password,
      email: username,
    });
  }

  return (
    <Box
      padding={2}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Card>
        <Box
          padding={2}
          width={500}
          component={Paper}
          display="flex"
          flexDirection="column"
          margin="dense"
        >
          <TextField
            value={username}
            label="Username"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
          />
          <TextField
            value={password}
            margin="normal"
            label="Password"
            placeholder="Password"
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
            <LoadingButton
              text="Login"
              onClick={doLogin}
              isLoading={loadingLogin}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
